<template>
  <div class="auth">
    <div class="auth__content">
      <form class="auth__form" @submit.prevent="onLoginFormSubmit">
        <c-heading color="#2D2D2D" as="h1" class="auth__form__title">
          Sign In
        </c-heading>
        <c-text my="3" fontWeight="500">
          Don't have a Vibrantfounder account?
          <c-link as="router-link" to="/auth/signup" fontWeight="600"
            >Sign up.</c-link
          >
        </c-text>
        <c-input-group mb="2em" h="60px" size="lg">
          <c-input-left-element class="auth__form__input-icon" h="full">
            <svg>
              <use href="./../../assets/icons/icon-mail.svg#icon_mail"></use>
            </svg>
          </c-input-left-element>
          <c-input
            borderRadius="10px"
            h="full"
            type="email"
            class="auth__form__input"
            placeholder="Email"
            v-model="$v.loginForm.email.$model"
          />
        </c-input-group>
        <div
          v-chakra="{
            mb: '1em',
          }"
        >
          <c-input-group h="60px" size="lg">
            <c-input-left-element class="auth__form__input-icon" h="100%">
              <svg>
                <use href="./../../assets/icons/icon-lock.svg#icon-lock"></use>
              </svg>
            </c-input-left-element>
            <c-input
              borderRadius="10px"
              h="100%"
              :type="showPassword ? 'text' : 'password'"
              class="auth__form__input"
              placeholder="Password"
              v-model="$v.loginForm.password.$model"
            />
            <c-input-right-element class="auth__form__input-toggle">
              <c-link @click="showPassword = !showPassword">
                {{ showPassword ? 'Hide' : 'Show' }}
              </c-link>
            </c-input-right-element>
          </c-input-group>
          <c-link
            fontWeight="500"
            color="#EF5323"
            fontSize="xs"
            as="router-link"
            to="/auth/forgot-password"
          >
            Forgot password?
          </c-link>
        </div>
        <c-button
          w="100%"
          h="60px"
          borderRadius="lg"
          size="lg"
          variant="solid"
          type="submit"
          :disabled="
            $v.loginForm.$invalid || isLoggingIn || isSigningWithGoogle
          "
          class="auth__form__button auth__form__button--primary"
        >
          <c-spinner v-if="isLoggingIn" color="#fff" thickness="3px" />
          <c-text v-else color="#fff">Sign In </c-text>
        </c-button>
        <c-flex justify="center" mt="4" align="center">
          <c-box mr="3">
            <c-box id="googleBtn" mx="auto" w="max-content"> </c-box>
          </c-box>
          <c-spinner
            v-if="isSigningWithGoogle"
            thickness="2px"
            color="blue.400"
          ></c-spinner>
        </c-flex>
      </form>
      <c-flex direction="column">
        <HeroGraphic :image="'hero-graphic-home.png'" :position="'right'" />
        <c-text
          w="75%"
          mx="auto"
          textAlign="center"
          fontStyle="italic"
          fontWeight="600"
          color="vc-orange.400"
          :display="{ base: 'none', lg: 'block' }"
        >
          It's not about ideas. It's about making ideas happen
        </c-text>
        <c-text
          mt="5"
          mx="auto"
          textAlign="center"
          color="vc-primary.400"
          fontStyle="italic"
          :display="{ base: 'none', lg: 'block' }"
        >
          Scott Belsky
        </c-text>
      </c-flex>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { required, email } from 'vuelidate/lib/validators';

import { login } from '@/services/auth.js';
// import { updateUser } from '@/services/user.js';

import config from '@/mixins/config.js';
import googleAuth from '@/mixins/google-auth.js';

import { onLogin } from '@/vue-apollo.js';
import HeroGraphic from './components/HeroGraphic.vue';

export default {
  components: { HeroGraphic },
  mixins: [config, googleAuth],
  data() {
    return {
      showPassword: false,
      loginForm: {
        email: null,
        password: null,
      },
      isLoggingIn: false,
      googleButtonText: 'signin_with',
    };
  },
  validations: {
    loginForm: {
      email: {
        email,
        required,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    ...mapActions({
      setUser: 'auth/setUser',
      setTempUser: 'auth/setTempUser',
      setToken: 'auth/setToken',
      setPendingInvites: 'auth/setPendingInvites',
    }),
    onLoginFormSubmit() {
      this.isLoggingIn = true;
      const form = { ...this.loginForm };
      login(form)
        .then(async (res) => {
          await this.onLogin(res.data);
          this.$gtag.event('login', { method: 'AuthServer' });
          this.isLoggingIn = false;
        })
        .catch((e) => {
          const errorMessage = e.response
            ? e.response.data.message
            : 'Please Try again';
          this.isLoggingIn = false;
          this.$toast({
            title: 'An error occurred.',
            description: errorMessage,
            status: 'error',
            position: 'top',
            duration: 3000,
          });

          if (e.response?.status === 403) {
            // eslint-disable-next-line no-unused-vars
            const { password, ...rest } = form;
            this.setTempUser(rest);
            this.$router.push('/auth/verify-email');
          }
        });
    },
    async onGoogleAuth(data) {
      await this.onLogin(data);
      this.isSigningWithGoogle = false;
      this.$gtag.event('login', { method: 'Google' });
    },
    async onLogin(data) {
      console.log('Auth data', data);
      this.setPendingInvites(data.user.pending_invites);
      this.setToken(data.tokenDetails);
      this.setUser(data.user);
      await onLogin(this.$apollo.provider.defaultClient, data.tokenDetails);
      this.getGlobalConfig();

      localStorage.setItem('OnlineStatus', 'true');

      // update user onlineStatus to true
      // await updateUser({
      //   id: data.user.id,
      //   set: { OnlineStatus: 'true' },
      // });

      if (data.user.isInviteFirstTime) {
        this.$router.push('/auth/new-invite');
      } else {
        // this.$router.push('/app/growth-framework');

        this.$router.push({ path: '/app/growth-framework' }).then(() => {
          // Wait for Vue to complete the route change before reloading
          this.$nextTick(() => {
            window.location.reload();
          });
        });
      }
    },
  },
};
</script>

<style lang="scss">
.auth {
  &__left {
    background-image: linear-gradient(
        to right top,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7)
      ),
      url('./../../assets/img/auth-left-male.jpg');
  }
}
</style>
